import * as React from "react"
import './Tyskie.css';
import Creative from "../../components/Creative"
import backgroundTyskie from "./images/tyskie_background.jpg"
import backImage from "./images/tyskie_backImage.png"
import photo1 from "./images/tyskie_photo_1.png"
import photo2 from "./images/tyskie_photo_2.png"
import photo3 from "./images/tyskie_photo_3.png"
import video from "./images/tyskie_video.mp4"
import SimpleSidebar from "../../components/layout"


const Tyskie = () => (
  <SimpleSidebar>
    <Creative
      className="tyskie"
      video={video}
      background={backgroundTyskie}
      backImage={backImage}
      heading="Przejdź na Ty"
      tags={["interaction", "choice", "animation",]}  
      tagBackground="#ad0c0c"
      tagColor="#ffffff"
      headingColor="#ffffff"
      variant="rightBottom"
    >
      <img src={photo1} className="tyskie_image photo_1"/>
      <img src={photo2} className="tyskie_image photo_2"/>
      <img src={photo3} className="tyskie_image photo_3"/>
    </Creative>
  </SimpleSidebar>
)

export default Tyskie
